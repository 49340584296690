.message-chunk {
  display: flex;
  flex-direction: row;
  padding: 0.5rem;
  margin-bottom: 0;
  background-color: rgba(200, 200, 255, 0.9);
  border-radius: 10px;
}

.message-chunk.user {
  background-color: rgba(200, 255, 200, 0.9);
}

.indicator-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  width: 2rem;
  margin-right: 0.5rem;
}

.message-icon {
  font-size: 1.5rem;
}

.message-content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.action-icons {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 0.5rem;
}

.message-chunk.user .message-icon {
  color: #3a7d34;
}

.message-chunk:not(.user) .message-icon {
  color: #3a3a7d;
}
