.game-interface {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.error-screen {
  font-size: 24px;
  color: #a26f4b;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
}
