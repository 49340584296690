.setup-container {
  text-align: center;
}

.setup-container p {
  margin-bottom: 1rem;
}

.current-question {
  font-weight: bold;
  margin-bottom: 2rem;
}

.suggested-answer {
  display: inline-block;
  background-color: #a26f4b;
  color: #fff;
  padding: 5px 15px;
  border-radius: 20px;
  cursor: pointer;
  margin-right: 8px;
  margin-bottom: 8px;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.4);
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
  transition: all 0.2s ease-in-out;
}

.suggested-answer:hover {
  background-color: #8c5e3a;
  box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.4);
}

.suggested-answer:active {
  background-color: #6b513d;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.4);
}

.custom-input {
  padding: 5px;
  margin-bottom: 8px;
  font-size: 16px;
  border: 2px solid #a26f4b; /* Use the same border color as the pill buttons */
  border-radius: 5px;
  background-color: #a26f4b; /* Same background color as the pill buttons */
  color: #fff; /* Same color as the pill buttons */
}

.custom-input::placeholder {
  color: #e4d8c7;
}

.button-pill {
  display: inline-block;
  background-color: #a26f4b;
  color: #fff;
  padding: 5px 15px;
  border-radius: 20px;
  cursor: pointer;
  margin-right: 8px;
  margin-bottom: 8px;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.4);
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
  transition: all 0.2s ease-in-out;
  border: none; /* Add this to remove the default button border */
  font-family: inherit; /* Add this to match the font family */
  font-size: inherit; /* Add this to match the font size */
}

.button-pill:hover {
  background-color: #8c5e3a;
  box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.4);
}

.button-pill:active {
  background-color: #6b513d;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.4);
}

.spinner {
  border: 4px solid #a26f4b;
  border-top: 4px solid #e4d8c7;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.custom-input.invalid {
  border-color: #cc4b4b; /* Use a different border color for invalid input */
}

.button-pill:disabled {
  background-color: #a26f4b80;
  color: #ffffff80;
  cursor: not-allowed;
  box-shadow: none;
}

.button-pill:disabled:hover {
  background-color: #a26f4b80;
  box-shadow: none;
}
