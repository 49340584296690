$bg-color: rgba(240, 230, 185, 0.8);
$frame-color: #d4af37;
$box-shadow-color: rgba(0, 0, 0, 0.4);
$text-color: #3c3c3c;
$scrollbar-thumb-color: rgba(0, 0, 0, 0.2);
$scrollbar-thumb-hover-color: rgba(0, 0, 0, 0.3);
$frame-thickness: 20px;
$frame-texture-slice-size: 200;

.quillmark-box {
  display: flex;
  flex-direction: column;
  position: relative;
  background-color: $bg-color;
  background-size: cover;
  background-image: url('./images/box/fantasy/background.png');
  background-repeat: repeat;
  border: $frame-thickness solid $frame-color;
  border-image: url('./images/box/fantasy/frame.png') $frame-texture-slice-size $frame-texture-slice-size $frame-texture-slice-size $frame-texture-slice-size round round;
  border-image-width: $frame-thickness;
  box-shadow: 3px 3px 10px $box-shadow-color;
  box-sizing: border-box;
  color: $text-color;
  max-width: 800px;
  max-height: 90%;

  &-transparent {
      opacity: 50%;
  }

  &-transparent:hover {
      opacity: 100%;
  }

  &-semitransparent {
      opacity: 80%;
  }

  &-semitransparent:hover {
      opacity: 100%;
  }

  &-content-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 15px;
    padding-top: 0;
    padding-bottom: 0;
    box-sizing: border-box;

    &::-webkit-scrollbar {
      width: 10px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $scrollbar-thumb-color;
      border-radius: 5px;
      background-clip: padding-box;
      border: 2px solid transparent;
    }

    &::-webkit-scrollbar-track {
      display: none;
    }

    &::-webkit-scrollbar-thumb:hover {
      background-color: $scrollbar-thumb-hover-color;
    }
  }

  &-box-decorations {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;

    & .embellishment {
      position: absolute;
      width: $frame-thickness;
      height: $frame-thickness;
      background-image: url('./images/box/fantasy/frame-decoration-bottom.png');
      background-repeat: no-repeat;
      background-size: contain;

      &.top {
        top: -$frame-thickness;
        left: 50%;
        transform: translateX(-50%) rotate(180deg);
      }

      &.right {
        top: 50%;
        right: -$frame-thickness;
        transform: translateY(-50%) rotate(-90deg);
      }

      &.bottom {
        bottom: -$frame-thickness;
        left: 50%;
        transform: translateX(-50%);
      }

      &.left {
        top: 50%;
        left: -$frame-thickness;
        transform: translateY(-50%) rotate(90deg);
      }
    }
  }
}
