.invitation-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgba(210, 180, 140, 0.9);
  padding: 20px;
  border-radius: 10px;
  width: 400px;
  max-width: 80%;
  margin: 0 auto;
}

.large-text {
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 20px;
}

.google-login-container {
  margin-bottom: 15px;
}
