.chat-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.chat-log {
  flex-grow: 1;
  overflow-y: auto;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.spinner {
  margin: 1rem auto;
  /* Add your preferred loading spinner styles here */
}

.chat-form {
  display: flex;
  padding: 1rem;
  /* background-color: #a26f4b; */
  align-items: center;
  position: relative;
}

.chat-input {
  flex-grow: 1;
  padding: 0.5rem;
  border: none;
  border-radius: 5px;
  background-color: #fff;
  outline: none;
  margin-right: 1rem;
}

.send-button,
.menu-button {
  font-size: 1rem;
  padding: 6px 12px;
  color: #fff;
  background-color: #8c5e3a;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

.send-button:hover,
.menu-button:hover {
  background-color: #6b513d;
}

.send-button:disabled,
.menu-option:disabled {
  background-color: #a26f4b;
  cursor: not-allowed;
}

.menu-button {
  font-size: 1rem;
  padding: 6px 12px;
  background-color: transparent;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

.menu-button:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

.menu-dropdown {
  position: absolute;
  right: 0;
  bottom: 100%;
  margin-bottom: 4px;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 4px 0;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 10;
}

.menu-option {
  display: block;
  padding: 6px 12px;
  font-size: 1rem;
  text-align: left;
  cursor: pointer;
  border: none;
  width: 100%;
  box-sizing: border-box;
  text-decoration: none;
  transition: all 0.2s ease-in-out;
  color: #fff;
  background-color: #8c5e3a;
}

.menu-option:hover {
  background-color: #6b513d;
}

.menu-option:disabled {
  background-color: #a26f4b;
  cursor: not-allowed;
}
