.game-title {
  margin-top: 0;
  margin-bottom: 10px;
  font-size: 1.25em;
}

p {
  margin: 5px 0;
}

strong {
  font-weight: bold;
}
