.back-button-container {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.back-button {
  display: inline-block;
  /* other styles remain the same */
}
