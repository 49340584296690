.game-list {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
  max-height: 80vh;
  overflow-y: auto;
  padding: 10px;
}

/* Media query for tall screens */
@media (orientation: portrait) {
  .game-list {
    grid-template-columns: 1fr;
  }
}

.game-list-item {
  align-items: center;
  justify-content: center;
  padding: 10px;
  background-color: #a26f4b;
  color: #fff;
  border-radius: 5px;
  cursor: pointer;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.4);
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
  transition: all 0.2s ease-in-out;
}

.game-list-item:hover {
  background-color: #8c5e3a;
  box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.4);
}

.game-list-item:active {
  background-color: #6b513d;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.4);
}
