$frame-thickness: 20px;
$portrait-top-bar-height: $frame-thickness * 2 + 70px;
$portrait-logo-size: 0.75 * ($portrait-top-bar-height - $frame-thickness * 2);

.app {
  width: 100vw;
  display: flex;
  height: 100vh;
  overflow: hidden;
  background-image: url(./images/main-bg.jpg);
  background-size: cover; /* Add this line */
  background-position: center; /* Add this line */
  z-index: 0;
}

.background-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background-size: cover;
  background-position: center;
  opacity: 0;
  transition: opacity 1s;
  pointer-events: none;
  z-index: 1;
}

/* Media query for tall screens */
@media (orientation: portrait) {
  .app {
    flex-direction: column;
  }
}

.navbar {
  width: 20%;
  height: 100%;
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 10;
}

/* Media query for tall screens */
@media (orientation: portrait) {
  .navbar {
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
}

a.logo {
  display: block;
  text-align: center;
}

img.logo {
  width: 80%;
  margin-top: 20px;
}

.version {
  color: #6b513d;
  font-size: 0.8rem;
  margin-bottom: 1rem;
  font-weight: bold;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
}

.main-area {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 10;
}

.main-area .block-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  position: relative;
}

.main-area > .version-number {
  position: absolute;
  bottom: 5vh;
  left: 50%;
  transform: translateX(-50%);
}

.create-game-button {
  font-size: 1.2rem;
  padding: 10px 20px;
  color: #fff;
  background-color: #a26f4b;
  border: none;
  border-radius: 5px;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.4);
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
}

.create-game-button:hover {
  background-color: #8c5e3a;
  box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.4);
}

.create-game-button:active {
  background-color: #6b513d;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.4);
}

.error-notification {
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  background-color: #a26f4b;
  color: #ffffff;
  padding: 10px 20px;
  border-radius: 0 0 5px 5px;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.4);
  z-index: 1000;
  font-size: 1rem;
  font-weight: bold;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
}

@media (orientation: landscape) {
  .navbar {
    top: 1vh;
    height: 50vh;
    left: 1vw;
    width: 18vw;
    opacity: 50%;
  }

  .main-area {
    left: 20vw;
    top: 0;
    height: 100vh;
    width: 80vw;
  }

  .main-area > .version-number {
    display: none;
  }
}

@media (orientation: portrait) {
  .navbar {
    top: 0.5cm;
    left: 0.5cm;
    width: calc(100vw - 1cm);
    height: $portrait-top-bar-height;
    opacity: 50%;
  }

a.logo {
    display: inline-block;
}
  img.logo {
    display: inline-block;
    width: $portrait-logo-size;
    height: $portrait-logo-size;
    margin-top: 0;
  }

  .version-number {
    display: inline-block;
  }

  .navbar > .version-number {
    display: none;
  }

  .main-area {
    width: 100vw;
    top: $portrait-top-bar-height;
    left: 0;
    height: calc(100% - $portrait-top-bar-height);
  }

  .main-area .block-container {
    height: calc(100% - 15vh);
    margin-bottom: 15vh;
  }
}
