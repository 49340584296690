$base-neutral: #333333;
$base-green: #008f00;
$base-red: #8f0000;
$hover-intensity: 10%;

@mixin icon-base {
    height: 48px;
    width: auto;
    margin-left: 0.25rem;
    margin-right: 0.25rem;
}

.icon-default {
    @include icon-base;
    fill: $base-neutral;
}

.icon-default:hover {
    fill: lighten($base-neutral, 10%);
}

.icon-red {
    @include icon-base;
    fill: $base-red;
}

.icon-red:hover {
    fill: lighten($base-red, 5%);
}

.icon-green {
    @include icon-base;
    fill: $base-green;
}

.icon-green:hover {
    fill: lighten($base-green, 5%);
}
