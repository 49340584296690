.text-box {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.main-content {
  width: 100%;
  padding-right: 10px;
  margin-bottom: 15px;
}

.main-content h2 {
  font-weight: bold;
  text-align: center;
  margin-bottom: 15px;
}

.main-content h3 {
  font-weight: bold;
  margin-bottom: 10px;
}

.main-content p {
  margin-bottom: 15px;
}

.main-content a {
  color: #4b4b4b;
  text-decoration: none;
  transition: 0.2s;
}

.main-content a:hover {
  color: #6a6a6a;
}

.main-content em {
  font-style: italic;
}

.main-content strong {
  font-weight: bold;
}

.main-content ul,
.main-content ol {
  list-style-position: inside;
  margin: 0 0 15px;
  padding-left: 20px;
}

.main-content li {
  margin-bottom: 5px;
}
