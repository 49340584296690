.explain-box {
    max-width: 90%;
}

.explain-container {
  .explain-consultation {
    margin-bottom: 2rem;

    h3 {
      margin-bottom: 1rem;
    }

    .explain-messages-container {
      overflow: hidden;
      position: relative;

      .explain-messages {
        .explain-message {
          max-width: 60%;
          margin-bottom: 1rem;

          &.explain-message-user {
            background-color: #e6f5ff;
            padding: 0.5rem 1rem;
            border-radius: 8px;
          }

          &.explain-message-assistant {
            background-color: #e6ffe6;
            padding: 0.5rem 1rem;
            border-radius: 8px;
          }

          &.explain-message-system {
            background-color: #ffe6e6;
            font-style: italic;
            color: #666;
            padding: 0.5rem 1rem;
            border-radius: 8px;
          }

          &.explain-message-request {
            float: left;
            clear: both;
            margin-right: 30px;
          }

          &.explain-message-response {
            float: right;
            clear: both;
            margin-left: 30px;
          }
        }
      }
    }
  }
}
