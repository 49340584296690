.create-game-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgba(210, 180, 140, 0.9);
  padding: 20px;
  border-radius: 10px;
  width: 400px;
  max-width: 80%;
  margin: 0 auto;
}

.form-group {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
}

.form-group label {
  font-weight: bold;
  margin-bottom: 5px;
}

.form-group select,
.form-group input {
  padding: 10px;
  font-size: 16px;
  border-radius: 5px;
}

.button-container {
  display: flex;
  justify-content: space-around;
  width: 100%;
}

.button {
  font-size: 18px;
  padding: 10px 20px;
  background-color: #f0e68c;
  border: none;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
  cursor: pointer;
  transition: 0.2s;
}

.button:hover {
  background-color: #f0e68c;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.5);
  transform: translateY(-2px);
}

.button:active {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
  transform: translateY(1px);
}
