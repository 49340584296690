$frame-thickness: 15px;
$frame-texture-slice-size: 200;
$box-shadow-color: black;

.quillmark-button {
  border: $frame-thickness solid brown;
  border-image: url('./images/buttons/fantasy/lightbrown.png') stretch stretch;
  border-image-slice: $frame-texture-slice-size fill;
  border-image-width: $frame-thickness;
  box-shadow: 3px 3px 10px $box-shadow-color;
  text-decoration: none;
  font-weight: bold;
  color: #c7a893;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
  font-size: 1.2rem;

  &:hover {
      border-image: url('./images/buttons/fantasy/lightbrown-p20.png') stretch stretch;
      border-image-slice: $frame-texture-slice-size fill;
      border-image-width: $frame-thickness;
      color: #d9c5b7;
  }

  &:active {
      border-image: url('./images/buttons/fantasy/lightbrown-m20.png') stretch stretch;
      border-image-slice: $frame-texture-slice-size fill;
      border-image-width: $frame-thickness;
      color: #b48b6e;
  }
}
